<template>
	<div>
		<s-crud
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			label="Categorías por estado de fruta"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Categoría"
								v-model="props.item.TypeCategoryFrozen"
								:def="1326"
							></s-select-definition>
						</v-col>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								:def="1173"
								v-model="props.item.TypeCultive"
							></s-select-definition>
						</v-col>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-select-fruit-state
								label="Estado Fruta"
								:TypeCultive="props.item.TypeCultive"
								v-model="props.item.FtsID"
							></s-select-fruit-state>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sCategoryStateFruitService from "@/services/FrozenProduction/CategoryStateFruitService.js";
	import SSelectFruitState from '../../../components/FrozenProduction/FruitState/SSelectFruitState.vue';

	export default {
		components: {
			SSelectFruitState,
		},

		data() {
			return {
				filter: {},
				config: {
					model:{
						CsfID: "ID"
					},
					service: _sCategoryStateFruitService,
					headers: [
						{text: "ID", value: "CsfID"},
						{text: "Categoría", value: "TypeCategoryFrozenName"},
						{text: "Tipo Cultivo", value: "TypeCultiveName"},
						{text: "Estado Fruta", value: "FtsName"},
					]
				}
			}
		},

		methods: {
			save(item) {
				item.save();
			}
		},
	}
</script>