<template
	><div style="padding-top:-2px;margin-top:-2px;">
		<v-label
			><b>{{ label }}</b></v-label
		>
		<s-select-generic
			class="text"
			itemvalue="FtsID"
			:disabled="disabled"
			:value="value"
			id="FtsID"
			itemtext="FtsName"
			:return-object="returnObject"
			@input="input($event)"
			:config="config"
			:full="full"
			:text="text"
			:clearable="clearable"
			:noDefault="noDefault"
      :readonly="readonly"
		>
		</s-select-generic>
	</div>
</template>

<script>
	import _sService from "@/services/FrozenProduction/FruitStateService.js";
	export default {
		components: {},
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			value: null,
			text: { type: String, default: "" },
			label: {
				type: String,
				default: "",
			},
			returnObject: {
				type: Boolean,
				default: false,
			},
			TypeCultive: {
				type: Number,
				default: 0,
			},
			noDefault: {
				type: Boolean,
				default: false,
			},
			full: {
				type: Boolean,
				default: false,
			},
			readonly: {
				type: Boolean,
				default: false,
			},
		},
		watch: {
			value(value) {
				this.item = value;
			},
			TypeCultive() {
				this.config.params.TypeCultive =
					this.TypeCultive == null ? 0 : this.TypeCultive;
			},
		},
		methods: {
			input(val) {
				this.$emit("input", val);
			},
		},
		data: () => ({
			item: 0,
			val: "",
			items: [],
			config: {},
		}),

		created() {
			this.config = {
				url: _sService.GetUrllistVariaty(),
				title: this.label,
				params: {
					TypeCultive: this.TypeCultive == null ? 0 : this.TypeCultive,
					search: "",
					requestID: this.$fun.getUserID(),
				},
			};
		},
	};
</script>
